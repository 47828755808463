@import 'custom';

.popup  {
  position: absolute;
  background-color: white; 
  border-radius: 8px; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); 
  padding: 10px; 
  z-index: 1000; 
  text-align: center;
  font-size: 12px;
}

.popupSignUp  {
  top: 50% !important;
  left: 50% !important; 
  transform: translate(-50%, -50%) !important; 
  width: 80% !important; /* Adjust width as needed */
  max-width: 500px;
  border-top: 6px solid $green;
  padding-top:20px !important;
}

  .popupDashboard {
    top: 80px; /* Ajuste la distance sous la cloche */
    left: 76%;
    transform: translateX(-76%);
    width: 270px !important;
    height: 100px !important;
    border-radius: 3px;
    background:  #F5F5F0;
    padding: 30px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    border-top: solid 1px #dcdce0 !important;
  }

  .popupDashboardMobile {
   // top:25% !important;
    right: 0% !important;
  }
  
  .popupDashboard::before {
    content: "";
    position: absolute;
    top: -16px; /* Adjust this to position the arrow */
    left: 80%;
    transform: translateX(-50%);
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #F5F5F0 transparent; /* Adjust color based on background */
    z-index: 10;
  }

  .popupDashboard::after {
    content: "";
    position: absolute;
    top: -20px;
    left: 80%;
    transform: translateX(-50%);
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent #dcdce0 transparent; /* Match tooltip background */
    z-index: 9;
  }

  .popup button {
    margin: 10px; /* Add spacing between buttons */
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    min-width: 250px !important;
    min-height: 40px !important;
  }
  
  .popup button:hover {
    background-color: #0056b3; /* Darken on hover */
  }
  
  /* Backdrop style */
  .backdrop, .backdropDashboard {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
    z-index: 999; /* Behind the popup but above other content */
  }
  
  .backdropDashboard {
    background-color: rgba(0, 0, 0, 0); /* Transparent black overlay */
  }