@import 'custom';

.css-11yukd5-MuiTabs-indicator {
  background-color: $green !important;
}

.MuiAccordion-region {
  background-color: $white !important;
}

.MuiCollapse-wrapperInner {
  background-color: $white !important;
}

.MuiInput-root {
  font-family: 'Graphik Light', sans-serif !important;
  font-weight: 400;
}

.MuiFormLabel-root {
  font-family: 'Graphik Light', sans-serif !important;
  font-weight: 400 !important;
}

.MuiButton-root {
  text-transform: capitalize !important;
}

.css-mjlvw-MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.css-6psz3a-MuiButtonBase-root-MuiToggleButton-root{
  border: none !important;
}

.MuiFormControlLabel-label {
  font-family: 'Graphik Light', sans-serif !important;
  font-weight: 400 !important;
}

.MuiOutlinedInput-input {
  font-family: 'Graphik Light', sans-serif !important;
  font-weight: 400 !important;
}

.react-tel-input .special-label {
  left: 10px !important;
}

.MuiAutocomplete-listbox {
  font-family: 'Graphik Light', sans-serif !important;
  font-weight: 400 !important;
}
.Mui-focused:after
{
  border-color:gray !important;
  color:$green !important;
}
label.Mui-focused{color:gray !important;}
input.Mui-focused .MuiOutlinedInput-notchedOutline{border-color:$green!important; }
div.Mui-focused .MuiOutlinedInput-notchedOutline{border-color:$green!important;}

 // .row-entity-name{
  .css-go819x-MuiTableCell-root {
    padding: none !important;
}//}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
     margin: 0px 0!important; 
}
