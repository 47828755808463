@import 'custom';

.memo-formatting {
  line-height: 1.5em;

  h1 {
    font-size: 2em;
    font-family: 'Titling Gothic FB Normal', sans-serif !important;
    font-weight: 400;
  }

  u {
    //font-size: 0.8em;
    //font-family: 'Titling Gothic FB Normal', sans-serif !important;
    //font-weight: 400;
    //margin: 10px;
    //display: block;
    color: $green;
  }

  pre {
    white-space: normal;
  }

  h2 {
    font-size: 1.5em;

    &.section-title {
      color: $green;
    }
  }

  h3 {
    font-size: 1.1em;
    font-family: 'Titling Gothic FB Normal', sans-serif !important;
    font-weight: 400;
    color: $green4;
  }

  h4 {
    font-size: 1.1em;
  }

  p {
    font-size: 1em;
  }

  span {
    font-size: 16px !important;
    font-family: 'Graphik', sans-serif !important;
    font-weight: 400;
    line-height: 20px;
  }

  th,
  td {
    padding: 10px;
    border: 1px solid #000;
    font-family: 'Graphik Light', sans-serif !important;
    font-weight: 400;
  }

  tr:nth-child(odd) {
    background: $accordion-expended-color;
  }

  tr:nth-child(even) {
    background: #ecebe6;
  }

  tr:first-child {
    background: $green;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
  }

  table.team-table {
    tr:first-child {
      background: $accordion-expended-color;
      color: #000;
      font-weight: normal;
      font-size: 1em;
    }
  }

  img {
    max-width: 95%;

    display: block;
    margin: 0 auto;

    @media (max-width: 420px) {
      width: auto;
      // max-width: 80%;
      height: unset !important;
    }

    &.team-image {
      float: left;
      margin-right: 1em;
    }
  }

  iframe {
    @media (max-width: 420px) {
      padding: 0 !important;
       height: unset !important;
    }
  }

  strong {
    font-family: 'Graphik Light', sans-serif !important;
    font-weight: 400;
    text-align: justify;
    font-weight: bold;
  }

  div {
    font-family: 'Titling Gothic FB Normal', sans-serif !important;
    font-weight: 400;
    text-align: justify;
  }

  td div {
    font-family: 'Graphik', sans-serif !important;
    font-weight: 400;
    text-align: justify;
  }

  ul {
    padding-left: 1.5em;

    font-family: 'Graphik Light', sans-serif !important;
    font-weight: 400;
    text-align: justify;

    @media (min-width: 420px) {
      padding-left: 2em;
    }

    li {
      line-height: 1.5em;
      margin: 0.75em 0;
    }
  }

  .fr-video {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 51%;
    display: block;
  }

  .fr-video iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
}
