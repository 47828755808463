@font-face {
  font-family: 'Titling Gothic FB Normal';
  src: url('fonts/Titling-Gothic-FB-Normal-Medium.otf');
  font-style: normal;
}

@font-face {
  font-family: 'Graphik Light';
  src: url('fonts/Graphik-Light.otf');
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('fonts/GraphikRegular.otf');
  font-style: normal;
}

@font-face {
  font-family: 'P22 Mackinac Pro';
  src: url('fonts/P22-MackinacPro-Medium.otf');
  font-style: normal;
}
