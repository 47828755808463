body {
  margin: 0;
  font-family: sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

#root {
  height: inherit;
}

code {
  font-family: sans-serif, 'Courier New', monospace;
}

.app {
  flex: 1;
  //display: flex;
  flex-direction: column;
  width: 100%;
  //min-height: 100vh;
}
