@import 'custom';

.empty-layout {
  background-color: $green4 !important;
  width: 100%;
  height: 100%;
  max-width: 100vw !important;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  overflow-y: auto;
}

.empty-layout-white {
  background-color: $white !important;
  font-family: 'Graphik Light', sans-serif !important;
  font-weight: 400;
}

.main {
  position: relative;
  padding-top: 75px!important;
}

.footer {
  background: linear-gradient(#052d50, #478289, #9ed1e9);
  position: relative;

  color: white;
}

.checkbox {
  color: $green !important;
}

.l-text {
  color: #ababab !important;
}

.primary-c {
  color: $green !important;
}

.d-blue {
  color: $darkBlue !important;
}

.drop-down-btn {
  color: $green4 !important;
  //text-transform: none !important;
}

.g-c {
  color: $green !important;
}
.r-c {
  color: $red !important;
}

.w-c {
  color: $white !important;
}

.b-c {
  color: $black !important;
}

.g1-c {
  color: $green1 !important;
}

.g3-c {
  color: $green3 !important;
}

.g4-c {
  color: $green4 !important;
}
.g6-c {
  color: $green6 !important;
}

.g7-c {
  color:#3D9699!important;
}

.g1-bg {
  background-color: $green1 !important;
}

.g3-bg {
  background-color: $green3 !important;
}

.g3-b {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #4CBCBF!important;
}
.g4-bg {
  background-color: $green4 !important;
}
.mb-bg {
  background-color: $mediumBlue !important;
}

.g5-bg {
  background-color: $green5 !important;
}

.g-bg {
  background-color: #81c7cc !important;
}

.gr-bg {
  background-color: #E1E1E1 !important;
  border:none !important;
  //opacity: 0.4 !important;
}

.b-bg {
  background-color: #fbfbf9 !important;
}
.c-border-bottom {
  border-bottom: none !important;
}

.b-border-bottom {
  border-bottom: 4px solid #e1e1e1 !important;
}

.link:hover {
  text-decoration: underline !important;
}

.op-03 {
  opacity: 0.3 !important;
}

.f-size-28 {
  font-size: 28px !important;
}
.f-size-24 {
  font-size: 24px !important;
}

.f-size-22 {
  font-size: 22px !important;
}

.f-size-20 {
  font-size: 20px !important;
}

.f-size-18 {
  font-size: 18px !important;
}

.f-size-16 {
  font-size: 16px !important;
}

.f-size-14 {
  font-size: 14px !important;
}

.f-size-13 {
  font-size: 13px !important;
}

.f-size-12 {
  font-size: 12px !important;
}

.f-size-11 {
  font-size: 11px !important;
}

.f-size-10 {
  font-size: 10px !important;
}

.f-size-8 {
  font-size: 8px !important;
}

.f-size-9 {
  font-size: 9px !important;
}

.f-size-09 {
  font-size: 0.9rem !important;
}

.f-smaller {
  font-size: smaller;
}
.w-10 {
  width:10% !important;
}
.w-13 {
  width:13% !important;
}

.w-15 {
  width:15% !important;
}

.w-25 {
  width:25% !important;
}

.w-20 {
  width:20% !important;
}
.w-30 {
  width:30% !important;
}


.w-40 {
  width:40% !important;
}

.accordion {
  margin-bottom: 13px !important;
}

.accordion-details {
  background-color: $white !important;
  color: black;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 1px !important;
}

.table-company-img {
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  border: 1px solid #e1e1e1;
  margin: auto 15px auto auto;
}

.h-w-45 {
  height: 45px;
  width: 45px;
}

.startup-company-img {
  padding: 4px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 10%);
  border: 1px solid #e1e1e1;
  margin-right: 20px;
  max-width: 130px;
  max-height: 130px;
}

.startup-company-amount {
  padding: 4px;
  border-radius: 4px;
  border: 1px solid $green;
  margin-right: 20px;
}

.table-company-label {
  font-size: 14px;
  font-weight: 700;
  color: $linkColor;
  margin: auto;
  cursor: pointer;
}

.link-color {
  color: $linkColor;
}

.table-company-sector {
  color: #333;
  @extend .f-f-g;
  text-transform: capitalize !important;
  @extend .f-w-300;
  @extend .f-size-13;
}

.invest-btn {
  font-size: 12px !important;
  font-weight: 600;
  width: 80px !important;
  height: 20px;
  min-width: 80px;
  min-height: 20px;
  text-transform: capitalize !important;
  border-radius: 4px !important;
  margin-top: 3px !important;
}

.invest-btn:hover {
  background-color: $green4 !important;
  color: $white;
}

.security-type {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 10px;
  font-weight: 500 !important;
  background-color: #e1e1e1;
  border-radius: 20px;
  text-transform: capitalize;
  color: #464646;
}

.card-company-height {
  height: calc(100% - 20px);
}
.card-company:hover {
  box-shadow: 0px 5px 5px -1px rgb(0 0 0 / 50%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important;
}
.rollover-card-company{
  min-height:326px !important;
}
.card-company{
  min-height:400px;
}

.flexible {
  flex-grow: 1;
}

.card-action {
  background-color: $green !important;
}

.card-content {
  text-align: center;
  position: relative;
  width: 98%;
  height: 5rem;
}

.memo-card-content {
  text-align: center;
  position: relative;
  width: 100%;
}

.card-members {
  text-align: left;
  position: relative;
  width: 100%;
  padding-left:5px;
}

.muted-text {
  font-size: 0.7em !important;
}

.card-btn {
  color: $white !important;
  width: 100%;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .f-f-tgf;
  @extend .f-w-400;
  @extend .f-size-14;
}

.form-btn {
  color: $white !important;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend .f-f-tgf;
  @extend .f-w-400;
  @extend .f-size-14;
}

.company-circle-logo {
  border: 3px solid $accordion-expended-color;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgb(0 0 0 / 10%);
  background: $white;
}

.memo-company-card {
  margin: 10px;
  height: 100%;
}

.memo-company-logo {
  border: 2px solid $accordion-expended-color;
  height: 80px;
  width: 80px;
}

.memo-company-action {
  display: flex;
  justify-content: space-between;
  padding-top: 10px !important;
  height: 100%;
}

.btn {
  color: $white !important;
  background-color: $green !important;
  @extend .f-f-tgf;
  @extend .f-w-400;
  @extend .f-size-14;
}


.btn-disabled {
  color: $white !important;
  background-color: gray !important;
  @extend .f-f-tgf;
  @extend .f-w-400;
  @extend .f-size-14;
}

.btn-invest {
  color: $white !important;
  background-color: #3D9699!important;
  @extend .f-f-tgf;
  @extend .f-w-400;
}
.popup-btn-invest {
  color: $white !important;
  background-color: $green3!important;
  @extend .f-f-tgf;
  @extend .f-w-500;
  @extend .f-size-14;
  @extend .lh-16;
}
.popup-btn-invest.Mui-disabled {
  pointer-events: none;
  background-color: lighten($green3, 20%) !important;
}

.entities-next-btn {
  color: $white !important;
  background-color: $green3 !important;
}

.entities-next-btn {
  &:hover {
    background-color: #3d9699 !important;
  }
}

.btn-disabled {
  color: $white !important;
  background-color: $gray-400 !important;
}

.btn-banner {
  color: $green !important;
  background-color: $white !important;
  border-color: $green !important;
}

.btn-outlined-g4-c {
  color: $green4 !important;
  border-color: $green4 !important;
}

.btn-outlined {
  color: $green !important;
  border-color: $green !important;
}

.btn-outlined-show {
  color: $green4 !important;
  border-color: $green4 !important;
} 

.btn-outlined-disabled {
  color: $gray-600 !important;
  border-color: $gray-600 !important;
}

.btn-team {
  color: $green3 !important;
  font-size: 14px !important;
  font-style: italic !important;
  @extend .f-f-pmp;
  font-weight:400px!important;
  cursor: pointer;
}

.chip-outlined {
  border-radius: 5px !important;
  color: $green !important;
  border-color: $green !important;
  font-size: 12px !important;
}
.black{
  color:#000000DD;
}
.gray-100 {
  color: $gray-100 !important;
}

.gray-200 {
  color: $gray-200 !important;
}

.gray-300 {
  color: $gray-300 !important;
}

.gray-400 {
  color: $gray-400 !important;
}

.gray-500 {
  color: $gray-500 !important;
}

.gray-600 {
  color: $gray-600 !important;
}

.gray-700 {
  color: $gray-700 !important;
}

.gray-800 {
  color: $gray-800 !important;
}

.gray-900 {
  color: $gray-900 !important;
}

.bold {
  font-weight: bold !important;
}

.alert {
  background-color: #d32f2f !important;
}

.kyc-header {
  background-color: $green;
  padding: 20px;
}

.kyc-title {
  color: $green;
}

.Mui-checked {
  color: $green !important;
}

.table-header-entity-name-container {
 margin-right:10px;
 margin-top:0px !important;
  height: 32px;
  background-color: #506eb4;
  text-align: center;
  border-radius: 0 0 5px 5px;
}
.entity-name {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: 32px;
  margin-top:0px !important;
}
.table-entity-name.css-go819x-MuiTableCell-root {
    padding: 0 !important;
  }

/* fonts family*/
.f-f-tgf {
  font-family: 'Titling Gothic FB Normal', sans-serif !important;
}

.f-f-gl {
  font-family: 'Graphik Light', sans-serif !important;
}

.f-f-g {
  font-family: 'Graphik' !important;
}

.f-f-pmp {
  font-family: 'P22 Mackinac Pro', sans-serif !important;
}

/* fonts weight */
.f-w-0 {
  font-weight: 0 !important;
}

.f-w-300 {
  font-weight: 300 !important;
}
.f-w-400 {
  font-weight: 400 !important;
}

.f-w-500 {
  font-weight: 500 !important;
}

.f-w-600 {
  font-weight: 600 !important;
}

.f-w-700 {
  font-weight: 700 !important;
}

/* combine fonts family and weights */
.f-tgf-400 {
  font-family: 'Titling Gothic FB Normal', sans-serif !important;
  font-weight: 400;
}

.f-gl-400 {
  font-family: 'Graphik Light', sans-serif !important;
  font-weight: 400 !important;
}
.f-g-400 {
  font-family: 'Graphik', sans-serif !important;
  font-weight: 400 !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.capitalize:first-letter {
  text-transform: uppercase !important;
}

.preline {
  white-space: pre-line !important;
}

.pointer {
  cursor: pointer;
}

.italic {
  font-style: italic !important;
}

.df-sb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.df-c {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-f {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.j-c-c {
  justify-content: center;
}

.j-c-sb {
  justify-content: space-between;
}

.a-i-c {
  align-items: center;
}

.df-l {
  display: flex;
  align-items: center;
  justify-content: left;
}

.fdr-c {
  flex-direction: column;
}

.fdr-r {
  flex-direction: row;
}


.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}



.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-15 {
  margin-left: 15px !important;
}
.ml-16 {
  margin-left: 16px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-15 {
  margin-right: 15px !important;
}
.mr-16 {
  margin-right: 16px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-19 {
  margin-top: 19px !important;
}

.mt-20 {
  margin-top: 20px !important;
}
.mt-28 {
  margin-top: 28px !important;
}
.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px !important;
}

.p-15 {
  padding: 15px !important;
}

.p-20 {
  padding: 20px !important;
}

.p-25 {
  padding: 25px !important;
}

.p-50 {
  padding: 50px;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-28 {
  padding-left: 28px;
}

.pr-5 {
  padding-right: 5px !important;
}

.pr-10 {
  padding-right: 10px;
}

.pr-20 {
  padding-right: 20px;
}
.pr-30 {
  padding-right: 30px!important;
}
.pr-52 {
  padding-right: 52px!important;
}
.pt-0 {
  padding-top: 0px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-40 {
  padding: 40px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}
.pb-5 {
  padding-bottom: 5px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-40 {
  padding-bottom: 40px;
}

.break-space{
  white-space: break-spaces;

}
.max-w-800 {
  max-width: 800px;
}

.textfield-uploader {
  width:350px;
}

.underline {
  text-decoration: underline !important;
}

.a-wrapper {
  text-decoration: none;
  color: #000;
  overflow: hidden;
  display: block;
  max-width: 300px;
}

.ribbon-wrapper {
  text-decoration: center;
  border-color: $green;
  border-width: 1px;
  border-style: solid;
  white-space: nowrap;
}

.ribbon-wrapper:before,
.ribbon-wrapper:after {
  border-style: solid;
  border-color: $green;
}

.ribbon {
  border-width: 1px 0;
  position: relative;
  display: block;
  padding: 0.4em 0 0.4em 0.4em;
  border-left: $green solid 1px;
}

.a-wrapper span {
  text-align: center;
  padding-right: 20px;
}

.ribbon:after {
  content: '';
  display: inline-block;
  border-bottom-width: 1px;
  border-right-width: 1px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  position: absolute;
  top: -61%;
  bottom: 48%;
  left: 0;
  right: 1.1em;
}

.ribbon-sector {
  white-space: nowrap;
  display: inline-block;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}
// startup page
.content-box {
  //padding: 0px 15px 15px 15px;

  p {
    font-family: 'Graphik Light', sans-serif !important;
    font-weight: 400;
    text-align: justify;
  }

  a {
    color: $green;
    text-decoration: none;
    white-space: nowrap;
  }
  @media (max-width: 420px) {
    padding: 10px;
  }

  h2 {
    font-size: 1.5em;
    font-weight: 400;
    margin: 0 0 20px 0;
    letter-spacing: 0.05em;
    color: $gray-banner;
  }

  .content {
    overflow-x: auto;
    width: 100%;
    line-height: 1.5em;
  }

  .limited-content {
    :nth-child(n + 3) {
      display: none;
    }
    :nth-child(2) {
      opacity: 0.3;
    }
  }
  .bold-nda-section {
    border: 3px solid #48b7b8;
    padding: 0;
  }
}


 .sticky {
  width: 100px !important;
  box-shadow: 10px 0 10px -8px #888 !important;
  position: 'sticky' !important;
  left: 0 !important;
  background: $white;
  z-index:1!important;
}

 .sticky:first-child {
  width: 100px !important;
  box-shadow: none!important;
  position: 'sticky' !important;
  left: 0 !important;
  background: $white!important;
  z-index:2!important;
}
.sticky-side-nav{
  max-width:240px;
  width:20%;
}
.sticky-side-nav,
.company-content {
  padding-top: 107px;
}

.nav-item {
  padding: 10px 20px !important;
  text-align: left !important;
  color: #000000 !important;
  font-weight: 0 !important;
  border: none !important;
  text-transform: none !important;
  display: inline-flex !important;
  justify-content: left !important;
  //border-left: 2px solid $green3 !important;
  font-family: 'Graphik'!important;
  font-weight: 300px!important;
  font-size: 16px!important;
  line-height: 20px!important;
  //white-space: nowrap;
 
}

.nav-item:hover,
.active {
  background-color: $green1 !important;
  border-left: 2px solid $green3 !important;
  color: #094E60!important;
  font-weight: 700!important;
}

.nav-item-menu{
  background-color:#E9FFFF!important;
}

.memo-nav:first-letter {
  text-transform: uppercase !important;
}

.memo-title {
  margin-left: 20px !important;
  margin-top: 10px !important;
  font-size: 20px !important;
}

.memo-section {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
}

.read-more {
  max-height: none;
}

.read-less {
  max-height: 130px;
  overflow: hidden;
  margin-bottom: 20px;
}

.justify {
  text-align: justify;
}

.center {
  text-align: center;
}
.pricing-terms {
  border: 1px solid $gray-500;
  padding: 2px;
  margin-top: 2px;
}


.lh-0-6 {
  line-height: 0.6!important;
}

.lh-1-5 {
  line-height: 1.5em;
}

.lh-100 {
  line-height: 50px;
}

.lh-36 {
  line-height: 36px;
}

.lh-26 {
  line-height: 26px;
}

.lh-24 {
  line-height: 24px;
}

.lh-22 {
  line-height: 22px;
}

.lh-20 {
  line-height: 20px;
}

.lh-18 {
  line-height: 18px;
}

.lh-16 {
  line-height: 16px;
}

.lh-5 {
  line-height: 5px!important;
}

.lh-2 {
  line-height: 2px!important;
}

.h-40 {
  height:100px;
}

.table-closed {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}
.table-cell-sticky {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  left: 0 !important;
  position: sticky !important;
  background-color: white;
  max-width: 100px;
}

.capital-position-card {
  padding: 15px;
  background: #f5f5f0;
  border-radius: 5px;
  color: $green4;
  
}

.limited-content {
  :nth-child(n + 3) {
    display: none;
  }
  :nth-child(2) {
    opacity: 0.3;
  }
}

.extra-rows {
  background-color: #f5f5f0;
}
.extra-rows-mobile {
  background-color: #f9f9f9;
}

.btn-sign-nda {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold !important;
  letter-spacing: 2.4px;
  height: 44px;
  padding: 11px;
  border-radius: 0 !important;
}

.sign-nda {
  background-color: $green !important;
  position: fixed  !important;
  bottom: 0;
  left: 50%;
  height: 300px;
  width: 240px;
  border-radius: 20px;
  margin-bottom: 15px;
  padding: 10px;
  z-index: 100;
  
  .btn-sign-nda {
    background-color: $white !important;
    color: $green !important;
  }
}

.sign-nda-mobile {
  height: 200px;
  width: 180px;
  left: 20%;
  padding: 1px;
  .btn-sign-nda {
    width:145px!important; 
    margin-left:15px;
  }
}
.nda-section {
  border: 3px solid #48b7b8;
  padding: 0;
  .nda-text-container {
    padding: 28px 60px 37px;
  }
  .btn-sign-nda {
    background-color: $green !important;
    color: $white !important;
  }
}
.phone-container {
  width: unset !important;
  margin: 8px !important;
}

.phone-container-0 {
  width: unset !important;
  margin: 0 !important;
}

.phone-input {
  border-radius: 4px !important;
  height: 40px !important;
  margin-top: 2px;
}

.phone-input-error {
  border-color:#D32F2F !important;
}

.min-w-300 {
  min-width: 300px !important;
}

// .register-input{
//   font-size:14px !important;

//    div {
//     font-size:14px !important;
//     height:42px !important;
//    padding:8px !important;
//   }
//   input {
//     padding: 0px !important;
//   }
// }

.react-tel-input .form-control:focus {
  border-color: $green !important;
  box-shadow: 0 0 0 1px $green !important;
}

.stepper {
  padding: 20px;
  margin-top: 20px;
  background-color: $green4 !important;
  span {
    height: 10px !important;
    width: 100%;
    span {
      background-color: $green3 !important;
    }
  }
}

.stepperButton {
  color: var(--White, #FFF);
  @extend .f-f-g;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 10px; /* 128.571% */
}

.startup-accordion {
  background-color: $accordion-expended-color !important;
}

.team-member-info {
  p {
    margin: 0 !important;
    margin-bottom: 5px !important;
  }
}

.esign {
  display: block;
  height: 1100px;
  width: 100%;
}

.drop_zone {
  border-color: $green !important;
}

.upload-file-container {
  border: 1px dashed #ababab;
  padding: 10px;
  display: flex;
  align-items: center;
}

.upload-file-container-mobile {
  background-color: $green1;
  cursor: pointer;
  padding: 10px;
  display: flex;
  align-items: center;
  margin-left: 15px;
}

.verify-banner {
  background-color: $gray-banner;
 
  min-height: 65px;
  display: flex;
  align-items: center;
  z-index: 200;
  position: fixed!important;
  width: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}

//badge acquired or close
.badge-wrapper {
  width: 105px;
  height: 108px;
  position: absolute;
  font-weight: bold;
  top: -3px;
  right: -3px;
}

.badge-green,
.badge-orange {
  text-align: center;
  font-size: 14px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  padding: 7px 0;
  left: -2px;
  top: 20px;
  width: 140px;
  color: #fff;
  -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  z-index: 90;
}

.badge-green {
  background-color: #63ba4c;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#63ba4c), to(#63ba4c));
  background-image: -webkit-linear-gradient(top, #63ba4c, #63ba4c);
  background-image: -moz-linear-gradient(top, #63ba4c, #63ba4c);
  background-image: -ms-linear-gradient(top, #63ba4c, #63ba4c);
  background-image: -o-linear-gradient(top, #63ba4c, #63ba4c);
}

.badge-orange {
  background-color: #dc8222;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#dc8222), to(#dc8222));
  background-image: -webkit-linear-gradient(top, #dc8222, #dc8222);
  background-image: -moz-linear-gradient(top, #dc8222, #dc8222);
  background-image: -ms-linear-gradient(top, #dc8222, #dc8222);
  background-image: -o-linear-gradient(top, #dc8222, #dc8222);
}

.badge-reduce-text {
  font-size: 12px !important;
}

.badge-green:before,
.badge-green:after {
  content: '';
  border-top: 3px solid #4d903b;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  position: absolute;
  bottom: -3px;
}

.badge-green:before {
  left: 0;
}
.badge-green:after {
  right: 0;
}

// mode banner
.mode-banner {
  background-image: linear-gradient(
    45deg,
    hsla(0, 0%, 100%, 0.15) 25%,
    transparent 0,
    transparent 50%,
    hsla(0, 0%, 100%, 0.15) 0,
    hsla(0, 0%, 100%, 0.15) 75%,
    transparent 0,
    transparent
  );
  background-color: #4cb9ba;
  background-size: 30px 30px;
  color: $white !important;
  padding: 0.3em 1em !important;
  font-size: 1em !important;
  font-weight: bolder !important;
  border-radius: 0 !important;
  text-align: center !important;
  justify-content: center;

  &.guest-mode-banner {
    background-image: none !important;
    background-color: #4cb9ba;
  }

  &.admin-mode-banner {
    background-color: #4cb9ba;
  }

  &.preview-mode-banner {
    background-color: #17273e;
  }

  &.impersonation-mode-banner {
    background-color: #ffc909;
  }
}

.date-picker {
  width: 100% !important;
}

.max-width-table {
  max-width: 890px;
  padding-bottom: 20px;
}

.max-width-memo {
  max-width: 960px;
  width: 80%;
}

.aspect-ratio {
  position: relative;
  width: 100%;
  height: 100% !important;
  //padding-bottom: 51%;
}

.aspect-ratio iframe {
  position: relative;
  width: 100%;
  height: 600px;
}

.nowrap {
  white-space: nowrap;
}

.pre {
  white-space: pre !important;
}

.fullwidth {
  width: 100%;
}

.limited-view-nonaccredited {
  padding: 10px;
  border-radius: 1px;
  border: solid 1px #4ab9bb;
  background-color: rgba(218, 218, 216, 0.24);

  a {
    cursor: pointer;
  }

  #statement {
    font-family: sans-serif;
    font-size: 13px;
    color: #a3a9b3;
  }
}

.sp-title {
  @extend .f-f-tgf;
  @extend .f-w-500;
  @extend .g4-c;
}

.sp-subtitle {
  @extend .f-f-tgf;
  @extend .f-w-500;
  @extend .f-size-18;
  color:#3D9699;
  padding-top: 20px;
  padding-bottom: 20px;
}

.f-rows-d {
  @extend .f-f-gl;
  @extend .f-w-600;
  @extend .f-size-14;
  @extend .p-10;
  display: flex;
  justify-content: left !important;
  align-items: flex-start !important;
}
.f-rows-d-several-dates {
  @extend .f-f-gl;
  @extend .f-w-600;
  @extend .f-size-14;
  display: flex;
  justify-content: left !important;
  align-items: flex-start !important;
}

.f-details-rows-d {
  @extend .f-f-gl;
  @extend .f-w-600;
  @extend .f-size-12;
  @extend .justify;
}

.f-rows-small {
 @extend .f-gl-400;
 @extend .f-size-10;
 @extend .lh-16;
}

.f-rows-medium {
 @extend .f-gl-400; 
 @extend .f-size-12;
 @extend .lh-16;
}

.gray-br {
  border-right: '1px solid #E1E1E1';
}

.gray-bg {
  background-color: #f5f5f0!important;
}

.gray1-bg {
  background-color:#F6F6F6!important;
}

.gray2-bg {
  background-color:#FAFAF8!important;
}

.gray3-bg {
  background-color:#DFDFDF!important;
}


.w-bg {
  background-color:#FFFFFF !important;
}


.mobileMenuItem {
  border-radius: 0;
  margin: 0 !important;
  box-shadow: none !important ;
  min-height: 60px;
}

.mobileMenuLink {
  display: flex;
  align-items: center;
  padding-left: 16px;
}

.mobileMenuItemExpand {
  background-color: $green3 !important;
}

.mobileMenuDetailsExpand {
  background-color: #e4f5f5 !important;
}

.mobileMenuItem:before,
.mobileMenuItemExpand:before {
  background-color: white!important;
}

.mobileSection {
  padding-left: 8px !important;
  padding-right: 16px !important;
  padding-top:10px!important;
}

.ws-10 {
  word-spacing: 10px;
}

.ws-5 {
  word-spacing: 5px;
}

.ls-5 {
  letter-spacing: 1px;
}

.badge {
  padding: 0 5px;
  border-radius: 7.5px;
  background-color: $green;
  font-size: 10px;
  font-weight: 600;
  line-height: 2.1;
  letter-spacing: 0.6px;
  color: #ffffff;
}

.founder-upload-section-label {
  margin-top: 15px !important;

  @media (max-width: 1000px) {
    margin-top: 10px !important;
  }
}

.toPdf {
  th,
  td {
    //border: none;
    padding: 0;
    justify-content:left!important;
    
  }
  .hidden {
    display: none;
  }
}

.border-top-iangels {
  border-top: 4px solid #4cb9ba !important;
}

.border-top-digital-assets {
  border-top: 4px solid #ff9f7a !important;//linear-gradient(90deg,#f43a5d,#ff9f7a) !important;
}

.border-top-lps {
  border-top: 4px solid #506eb4 !important; //linear-gradient(90deg,#506eb4,#76c7c7) !important;
}

.border-top-public-holdings {
  border-top: 4px solid #4cb9ba !important;
}

.border-total {
  border-top: 4px solid #094E60 !important;
}

.border-none {
    border: none !important;
}

.file-uploaded {
  white-space: nowrap; 
  width: 50%; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordionSummary {
  height:90px!important;
}

.accordionMobileSummary {
  height:120px!important;
}

.notes {
  font-family: 'Graphik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: justify;
  text-decoration-line: underline;
  text-transform: capitalize;
  color: #094E60;
  padding:10px;}

.sector {
  background-color: rgba(59, 69, 84, 0.8);
  color: #ffffff;
  font-size:.8em;
  font-weight:600;
  top:0;
  width:100%;
  height:30px;
  display:flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.auth0-lock-widget {
  width:100%!important;
  max-width:400px!important;
}

.rollover-info-more {
  background: #F5F5F0;
  border-radius: 4px;
  height: 24px!important;
  position: relative;
  padding: 5px;
  border-bottom: 0px!important;
}

.rollover-popup{
  background: #F5F5F0;
}
.rollover-footer{
  padding-left: 24px!important;
  padding-right: 24px!important;
}

.rollover-info-more .reinvested {
  color: transparent;
  width: 136px;
}

.f-popup-header-content {
  color: $white;
  @extend .f-f-tgf;
  @extend .f-w-500;
  @extend .f-size-18;
  @extend .lh-18;
  @extend .mb-20;
}
.f-popup-header-content-mobile {
  color: $white;
  @extend .f-f-tgf;
  @extend .f-w-500;
  @extend .f-size-16;
  @extend .lh-16;
}


.f-popup-headers {
  @extend .w-c ;
  @extend .f-f-tgf; 
  @extend .f-w-500 ;
  @extend .f-size-12; 
  @extend .lh-16;
 }

 .f-popup-content {
  @extend .w-c ;
  @extend .f-f-gl; 
  @extend .f-w-400 ;
  @extend .f-size-12; 
  @extend .lh-16;
 }

 .f-popup-sum {
  @extend .g4-c ;
  @extend .f-f-tgf; 
  @extend .bold;
  @extend .f-w-400 ;
  @extend .f-size-12; 
  @extend .lh-16;
 }

 .popup-tooltip{
  color: black;
  @extend .rollover-popup;
  @extend .f-f-gl; 
  @extend .f-w-400 ;
  @extend .f-size-14; 
  @extend .lh-16;

 }
.info-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px; /* Taille ajustable */
  height: 12px; /* Taille ajustable */
  margin-right:3px;
  border: 1px solid; /* Bordure en pointillé bleu */
  border-radius: 50px; /* Coins légèrement arrondis */
  background-color: white; /* Fond blanc */
}

.icon-text {
  font-weight: bold;
  font-family: Arial, sans-serif; /* Assurez une police propre */
}

.block {
  display: block;
}

.checkbox-container {
  display: flex;
  align-items: flex-start;

  line-height: 14px;
  margin-top: 15px;
  margin-left: 25px;
}

.checkbox-container input {
  margin-top: 0px; 
}

.checkbox-container label {
  margin-left: 8px; 
  display: block;
}

.congrats {
  width: 142px !important;
  height: 145px !important;
}
/* Background Overlay */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
}

/* Alert Box */
.alert-box {
  max-width: 90%; /* Prevents overflow */
  width: 400px; /* Default width */
  min-width: 250px;
  padding: 20px;
  position: relative;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  text-align: center;
  word-wrap: break-word;
}


/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px; /* Ensures it stays within bounds */
  font-size: 20px;
  background: none;
  border: none;
  cursor: pointer;
}


/* Make the alert responsive */
@media (max-width: 480px) {
  .alert-box {
    width: 70%;
    max-width: 70%;
    padding: 20px;
    margin: 0 auto;
    position: fixed; /* ou absolute si nécessaire */
    left: 50%;
    transform: translateX(-50%);
  }
}
