//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900,
) !default;

$blue: #0076fd !default;
$mediumBlue:#007880 !default;
$darkBlue: #094e60 !default;
$lightBlue: #00d3ff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #d63831 !default;
$orange: #eea84d !default;
$yellow: #ffc107 !default;
$green: #4cb9ba !default;
$green1: #d8eef0 !default;
$green3: #4cbcbf !default;
$green4: #094e60 !default;
$green5: #eefeff !default;
$green6: #81C7CC !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$accordion-expended-color: #f5f5f0 !default;
$gray-banner: #3b4554;

$gradientTop: $lightBlue;
$gradientBottom: $blue;

$linkColor: #4d7cd8 !default;

$colors: (
  blue: $blue,
  darkBlue: $darkBlue,
  lightBlue: $lightBlue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  green1: $green1,
  green3: $green3,
  green4: $green4,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $gray-600,
  gray-dark: $gray-800,
) !default;

$theme-colors: (
  primary: $blue,
  secondary: $lightBlue,
  success: $green,
  info: $cyan,
  warning: $yellow,
  danger: $red,
  light: $gray-100,
  dark: $gray-800,
) !default;
